import { OpenPip } from '@components/LiveOverlay/Tag/Pip';
import { useEnv } from '@hooks/useEnv';
import { useConfig } from '@providers/config.provider';
import { useIsDragging } from '@providers/dragging.provider';
import { useSettings } from '@providers/settingsFirebase.provider';
import { useStage } from '@providers/stage.provider';
import { useTracking } from '@providers/tracking.provider';
import { useVideo } from '@providers/video.provider';
import React, {
	ReactNode,
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react';
import Draggable from 'react-draggable';
import { TFunction } from 'i18next';
import i18next from 'i18next';

interface ElementPositionProps {
	child1: ReactNode;
	child2: ReactNode;
	t: TFunction<'translation', undefined>;
}

export function ElementPosition({ child1, child2, t }: ElementPositionProps) {
	const [dynamicHeight, setDynamicHeight] = useState(0);
	const [dynamicWidth, setDynamicWidth] = useState(0);
	const [showPip, setShowPip] = useState<boolean>(false);
	const [css, setCss] = useState<any>();
	const elementRef = useRef<any>(null);
	const [_, setPosition] = useState({
		posX: 0,
		posY: 0,
	});
	const [positionCss, setPositionCss] = useState('');
	const { setIsDragging, isDragging } = useIsDragging();

	const video = useVideo();
	const { config } = useConfig();
	const { status, showPip: _showPip } = useSettings();
	const { envIframe, envTag } = useEnv();
	const {
		isLiveAlreadyClosed,
		onRedirect,
		setIsLivePage,
		setLiveAlreadyClosed,
	} = useStage();
	const tracking = useTracking();
	const [isScrolling, setIsScrolling] = useState(false);
	const [hasViewEvent, setHasViewEvent] = useState(false);
	const [urlPath, setUrlPath] = useState<string>(window.location.href);
	const [isI18nInitialized, setIsI18nInitialized] = useState(false);

	const ctaTagPosition = config?.player_settings?.player_position?.split('-');
	const [lastPosition, setLastPosition] = useState({
		posX: 0,
		posY: 0,
		quadrant: config?.player_settings?.player_position,
	});
	const [startPosition, setStartPosition] = useState({
		posX: 0,
		posY: 0,
		quadrant: config?.player_settings?.player_position,
	});

	setInterval(() => {
		if (urlPath != window.location.href) {
			setUrlPath(window.location.href);
		}
	}, 500);

	useEffect(() => {
		if (status === "live" && _showPip) {
			const player: HTMLElement | null =
				document.querySelector(envIframe) ??
				document.querySelector(envTag);

			if (
				config.live_url !== window.location.href &&
				config.live_url !== urlPath &&
				document.querySelector(envIframe) === null
			) {
				setShowPip(true);
				setIsLivePage(false);
			} else {
				setIsLivePage(true);

				if (player && window.scrollY + 60 >= player.offsetHeight) {
					setShowPip(true);
				} else {
					setShowPip(false);
					setHasViewEvent(false);
				}
			}
		}
	}, [status, _showPip, urlPath, video.progress]);

	useEffect(() => {
		setDynamicHeight(window.innerHeight * 0.33);
		setDynamicWidth(window.innerHeight * 0.33 * 0.6);
	}, []);

	useEffect(() => {
		const InitialPosition =
			config?.player_settings?.player_position?.replace('-', ' ');
		if (InitialPosition?.split(' ')[0] == 'bottom') {
			setCss((v: any) => ({ ...v, bottom: 0, top: '' }));
		} else {
			setCss((v: any) => ({ ...v, top: 0, bottom: '' }));
		}

		if (InitialPosition?.split(' ')[1] == 'right') {
			setCss((v: any) => ({ ...v, right: 0, left: '' }));
		} else {
			setCss((v: any) => ({ ...v, left: 0, right: '' }));
		}
	}, []);

	useEffect(() => {
		if (!isLiveAlreadyClosed) {
			if (config.id === localStorage.getItem('bGl2ZUlk'))
				setLiveAlreadyClosed(true);
		}
	}, [isLiveAlreadyClosed]);

	useEffect(() => {
		if (status) {
			let scrollTimer: any;

			const handleScroll = () => {
				setIsScrolling(true);
				clearTimeout(scrollTimer);

				scrollTimer = setTimeout(() => {
					setIsScrolling(false);
				}, 1000);
			};
			window.addEventListener('scroll', handleScroll);
			return () => {
				window.removeEventListener('scroll', handleScroll);
				clearTimeout(scrollTimer);
			};
		}
	}, [showPip, status]);

	useEffect(() => {
		if (!isScrolling) {
			let player;
			if (document.querySelector(envIframe)) {
				player = document.querySelector(envIframe);
			} else if (document.querySelector(envTag)) {
				player = document.querySelector(envTag);
			}

			if (player !== null) {
				if (player instanceof HTMLElement) {
					if (window.scrollY + 100 >= player.offsetHeight) {
						setShowPip(true);
					} else {
						setShowPip(false);
						setHasViewEvent(false);
					}
				}
			}
		}
	}, [envIframe, envTag, isScrolling]);

	useEffect(() => {
		if (!hasViewEvent && showPip && video.progress) {
			tracking.track({
				event: 'pip_view',
				event_label: 'pip_view',
			});
			setHasViewEvent(true);
		}
	}, [hasViewEvent, showPip, video.progress]);

	useEffect(() => {
		if (i18next.isInitialized) {
			setIsI18nInitialized(true);
		} else {
			i18next.on('initialized', () => {
				setIsI18nInitialized(true);
			});
		}
	}, []);

	function calculateElementPosition() {
		const element = elementRef.current;
		if (element) {
			const rect = element.getBoundingClientRect();
			setPosition({
				posX: rect.left + window.scrollX,
				posY: rect.top + window.scrollY,
			});

			const infoPosition = {
				posX: rect.left + window.scrollX,
				posY: rect.top + window.scrollY,
				largura:
					window.innerWidth ||
					document.documentElement.clientWidth ||
					document.body.clientWidth,
				altura:
					window.innerHeight ||
					document.documentElement.clientHeight ||
					document.body.clientHeight,
			};

			setStartPosition({
				posX: lastPosition.posX,
				posY: lastPosition.posY,
				quadrant: lastPosition.quadrant,
			});

			const screenWidth = window.innerWidth;
			const screenHeight = window.innerHeight;

			let quadrant = '';

			if (
				infoPosition.posX < screenWidth / 2 &&
				infoPosition.posY < screenHeight / 2
			) {
				quadrant = 'top-left';
			} else if (
				infoPosition.posX >= screenWidth / 2 &&
				infoPosition.posY < screenHeight / 2
			) {
				quadrant = 'top-right';
			} else if (
				infoPosition.posX < screenWidth / 2 &&
				infoPosition.posY >= screenHeight / 2
			) {
				quadrant = 'bottom-left';
			} else {
				quadrant = 'bottom-right';
			}

			setLastPosition({
				posX: infoPosition.posX,
				posY: infoPosition.posY,
				quadrant,
			});

			setTimeout(() => {
				setIsDragging(false);
			}, 250);
		}
	}

	useEffect(() => {
		if (!isDragging) {
			tracking.track({
				event: 'drag_drop',
				event_label: 'drag&drop',
				position_start: startPosition,
				position_end: lastPosition,
			});
		}
	}, [isDragging, startPosition, lastPosition]);

	const onDrag = useCallback(() => {
		setIsDragging(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onStart = useCallback(() => {
		setIsDragging(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (!isI18nInitialized) {
		return null;
	}

	return (
		<>
			{showPip &&
				(!isLiveAlreadyClosed ? (
					<div>
						<Draggable
							onStart={onStart}
							onDrag={onDrag}
							onStop={calculateElementPosition}
							bounds="html"
							disabled={Boolean(
								!config.player_settings.position_can_change
							)}
						>
							<div
								className={positionCss}
								ref={elementRef}
								style={{
									display:
										video.progress && video.play
											? 'initial'
											: 'none',
									height: dynamicHeight,
									// width: dynamicWidth,
									position: 'fixed',
									zIndex: '2147483645',
									margin: '20px',
									...css,
								}}
							>
								{child1}
								<div
									style={{
										backgroundImage: `url("${
											import.meta.env.VITE_ASSETS_URL
										}/live_thumb/noise.gif")`,
										backgroundSize: 'cover',
										borderRadius: '8px',
										overflow: 'hidden',
										height: dynamicHeight,
										width: dynamicWidth,
										border: '1px inset rgba(0, 0, 0, 0.12)',
										position: 'relative',
										...css,
									}}
								>
									{child2}
								</div>
							</div>
						</Draggable>
					</div>
				) : (
					<div
						className={`block ${
							ctaTagPosition[0] === 'top' ? 'mt-[5%]' : 'mb-[5%]'
						}`}
						style={{ ...css, left: 0, position: 'fixed' }}
					>
						<OpenPip
							title={`${t('wearelive_pip')}`}
							text={t('clickWatch_pip')}
							theme={config?.player_settings?.color_tag}
							position={'left'}
							onClick={(e: any) => onRedirect(e)}
						/>
					</div>
				))}
		</>
	);
}
