import { addParamsToURL } from '@utils/formatUtil';
import { StreamType } from '@utils/types';
import React, {
	PropsWithChildren,
	createContext,
	useContext,
	useEffect,
	useState,
} from 'react';
import { useApi } from './api.provider';
import { useEmbedParams } from './embedParams.provider';
import { SettingsProvider } from './settingsFirebase.provider';
import I18NextHttpBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import i18next from 'i18next';

type ConfigType = {
	position_can_change: number;
	id: string;
	color_tag: any;
	player_settings: any;
	settings_meta: any;
	stream_meta: any;
	stream_type: StreamType;
	live_status: string;
	live_url: string;
	live_starts_at: string;
	language_slug: string;
};

interface ConfigProps {
	config: ConfigType;
}

export const ConfigContext = createContext({} as ConfigProps);

export function ConfigProvider({ children }: PropsWithChildren) {
	const api = useApi();
	const [config, setConfig] = useState({} as ConfigType);
	const { liveId, customerId, configEndpoint } = useEmbedParams();

	const embedParams = {
		liveId,
		customerId,
	};

	useEffect(() => {
		if (config.language_slug)
			i18next
				.use(initReactI18next)
				.use(I18NextHttpBackend)
				.init({
					backend: {
						loadPath: `${
							import.meta.env.VITE_ASSETS_URL
						}/languages/{{lng}}.json`,
						requestOptions: {
							cache: 'no-cache',
						},
					},
					load: 'currentOnly',
					lng: config.language_slug || 'pt-BR',
					fallbackLng: 'pt-BR',
				});
	}, [config.language_slug]);

	useEffect(() => {
		if (Object.values(embedParams).length > 0) getConfigInfo();
	}, []);

	function checkDate() {
		const now = new Date().getTime();
		const liveDate = new Date(config.live_starts_at).getTime();
		return liveDate > (now - 86400000) && liveDate < (now + 86400000);
	}

	function isLiveRenderable() {
		if (Object.keys(config).length > 0 && Boolean(config.player_settings?.active)) {
			return config.live_status === 'live' || (config.live_status === 'awaiting' && checkDate());
		}
		return false;
	}

	function getConfigInfo() {
		api.custom(addParamsToURL(configEndpoint!, embedParams))
			.then((res: ConfigType) => {
				if (res && res?.id) {
					setConfig(res);
				}
			})
			.catch((error: any) => {
				//console.log(error);
			});
	}
	return (
		<ConfigContext.Provider value={{ config }}>
			{isLiveRenderable() && (
				<SettingsProvider config={config}>{children}</SettingsProvider>
			)}
		</ConfigContext.Provider>
	);
}

export function useConfig() {
	return useContext(ConfigContext);
}
