export type ApiMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

export type ApiHeaders = {
	[key: string]: string;
};

export type ApiData = {
	setCustomer: (id: string) => void;
	withHeaders: (headers: ApiHeaders) => ApiService;
	get: (url: string) => Promise<any>;
	post: (url: string, body: any) => Promise<any>;
	put: (url: string, body: any) => Promise<any>;
	patch: (url: string, body: any) => Promise<any>;
	delete: (url: string) => Promise<any>;
	custom: (url: string, body?: any, method?: ApiMethod) => Promise<any>;
};

export class ApiService implements ApiData {
	token: string;
	internalToken: boolean;

	private apiUrl: string = import.meta.env.VITE_CMS_URL;
	private _headers: any;
	private _customerId: string | undefined;

	constructor(newToken = '') {
		this.token = newToken;
		this.internalToken = false;
	}

	private async _call(url: string, method: ApiMethod, body: any = null) {
		const response = await fetch(url, {
			method,
			body: body ? JSON.stringify(body) : null,
			headers: this._getHeaders(),
		});
		this._headers = {};
		return this._handleResponse(response);
	}

	public get(url: string): Promise<any> {
		return this._call(this.apiUrl + url, 'GET');
	}

	public post(url: string, body: any): Promise<any> {
		return this._call(this.apiUrl + url, 'POST', body);
	}

	public custom(
		url: string,
		body?: any,
		method: ApiMethod = 'GET'
	): Promise<any> {
		this._headers = {
			...this._headers,
		};
		return this._call(url, method, body);
	}

	public put(url: string, body: any): Promise<any> {
		return this._call(this.apiUrl + url, 'PUT', body);
	}

	public patch(url: string, body: any): Promise<any> {
		return this._call(this.apiUrl + url, 'PATCH', body);
	}

	public delete(url: string): Promise<any> {
		return this._call(this.apiUrl + url, 'DELETE');
	}

	// helper functions
	private _handleResponse(response: any) {
		return new Promise((res, rej) => {
			response.text().then((text: any) => {
				const data = text && JSON.parse(text);
				if (!response.ok) {
					const error = data || response.statusText;
					const errorData = { ...error, status: response.status };
					rej(errorData);
				}
				res(data);
			});
		});
	}

	public setToken(newToken: string, internal = false) {
		this.token = newToken;
		this.internalToken = internal;
	}

	public setCustomer(id: string) {
		this._customerId = id;
	}

	public withHeaders(headers: ApiHeaders) {
		this._headers = { ...this._headers, ...headers };
		return this;
	}

	private _getHeaders() {
		const headers = {
			Accept: 'application/json',
			'Content-Type': 'text/plain',
			...this._headers,
		};

		if (this.token)
			headers[!this.internalToken ? 'Authorization' : 'mimo-auth'] = !this
				.internalToken
				? `Bearer ${this.token}`
				: this.token;
		if (this._customerId) headers['mls-sci'] = `${this._customerId}`;
		return headers;
	}
}
