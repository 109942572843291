import { useFirebase, useObject } from '@hooks/useFirebase';
import { getDatabase, ref } from 'firebase/database';
import React, {
	PropsWithChildren,
	createContext,
	useContext,
	useEffect,
	useState,
} from 'react';

type SettingsType = {
	status: string;
	showPip: boolean;
};

export const SettingsProviderContext = createContext({} as SettingsType);

export function SettingsProvider({
	config,
	children,
}: PropsWithChildren<{
	config: any;
}>) {
	const [status, setStatus] = useState<any>(null);
	const [showPip, setShowPip] = useState<any>(null);

	const settingsApp = useFirebase('settings', {
		databaseURL: config?.settings_meta?.databaseUrl,
	});
	const [_status] = useObject(ref(getDatabase(settingsApp), '/live/status'));
	const [_showPip] = useObject(ref(getDatabase(settingsApp), '/showPip'));

	useEffect(() => {
		setStatus(_status?.val());
	}, [settingsApp, _status]);
	
	useEffect(() => {
		setShowPip(_showPip?.val());
	}, [settingsApp, _showPip]);

	return (
		<SettingsProviderContext.Provider value={{ status, showPip }}>
			{children}
		</SettingsProviderContext.Provider>
	);
}

export function useSettings() {
	return useContext(SettingsProviderContext);
}
